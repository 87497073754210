document.addEventListener("turbo:load", function() {
    const todos = document.querySelectorAll('.js-todo-checkbox');

    if (todos.length === 0) {
      return;
    }

    todos.forEach(todo => {
        todo.addEventListener("change", (event) => {
            const checkBox = event.target;
            // parent is a <button type="submit">
            const label = checkBox.closest("label")
            const parent = label.closest("form")
            parent.dispatchEvent(new Event('submit', {
                bubbles: true,
                cancelable: true
            }))
        })
    })

})