import IMask from "imask";

document.addEventListener("turbo:load", () => {
  const formElement = document.querySelector(".js-masked-form");

  if (!formElement) {
    console.warn("No form element found");
    return;
  }

  const inputElement = formElement.querySelector(".js-masked");

  if (!inputElement) {
    console.warn("No input elements found");
    return;
  }

  const maskOptions = getMaskOptions(inputElement);
  IMask(inputElement, maskOptions);
  formElement.addEventListener("turbo:submit-start", handleFormSubmit)
});

function getMaskOptions(inputElement) {
  if (inputElement.classList.contains('js-phone-mask') && inputElement.classList.contains('js-email-mask')) {
    return {
      mask: getPhoneAndEmailMasks(),
      prepareChar: (str) => str.toLowerCase(),
      dispatch: dispatchMask,
    };
  } else if (inputElement.classList.contains('js-phone-mask')) {
    return {
      mask: getPhoneMasks(),
      prepareChar: (str) => str.toLowerCase(),
      dispatch: dispatchMask,
    };
  } else if (inputElement.classList.contains('js-email-mask')) {
    return {
      mask: getEmailMask(),
      prepareChar: (str) => str.toLowerCase(),
    };
  } else {
    console.warn('No specific mask class found, applying default phone and email mask');
    return {
      mask: getPhoneAndEmailMasks(),
      prepareChar: (str) => str.toLowerCase(),
      dispatch: dispatchMask,
    };
  }
}

function getPhoneMasks() {
  return [
    { mask: "000 000 00 00", startsWith: "0" }, // Swiss local number
    { mask: "+33 0 00 00 00 00", startsWith: "+33" }, // France
    { mask: "+49 000 0000000", startsWith: "+49" }, // Germany
    { mask: "+34 000 000 000", startsWith: "+34" }, // Spain
    { mask: "+39 00 0000 0000", startsWith: "+39" }, // Italy
    { mask: "+351 000 000 000", startsWith: "+351" }, // Portugal
    { mask: "+31 0 0000 0000", startsWith: "+31" }, // Netherlands
    { mask: "+32 0 000 00 00", startsWith: "+32" }, // Belgium
    { mask: "+46 000 000 0000", startsWith: "+46" }, // Sweden
    { mask: "+47 000 00 000", startsWith: "+47" }, // Norway
    { mask: "+41 00 000 00 00", startsWith: "+41" }, // Switzerland
    { mask: "+43 000 00000", startsWith: "+43" }, // Austria
    { mask: "+45 00 00 00 00", startsWith: "+45" }, // Denmark
    { mask: "+358 0 000 0000", startsWith: "+358" }, // Finland
    { mask: "+354 000 0000", startsWith: "+354" }, // Iceland
    { mask: "+353 0 000 0000", startsWith: "+353" }, // Ireland
    { mask: "+30 000 000 0000", startsWith: "+30" }, // Greece
    { mask: "+36 00 000 000", startsWith: "+36" }, // Hungary
    { mask: "+48 000 000 000", startsWith: "+48" }, // Poland
    { mask: "+40 000 000 000", startsWith: "+40" }, // Romania
    { mask: "+420 000 000 000", startsWith: "+420" }, // Czech Republic
    { mask: "+421 000 000 000", startsWith: "+421" }, // Slovakia
    { mask: "+385 00 000 0000", startsWith: "+385" }, // Croatia
    { mask: "+359 00 000 000", startsWith: "+359" }, // Bulgaria
    { mask: "+372 0000 0000", startsWith: "+372" }, // Estonia
    { mask: "+371 00 000 000", startsWith: "+371" }, // Latvia
    { mask: "+370 000 00000", startsWith: "+370" }, // Lithuania
    { mask: "+352 000 000", startsWith: "+352" }, // Luxembourg
    { mask: "+356 0000 0000", startsWith: "+356" }, // Malta
    { mask: "+386 0 0000 0000", startsWith: "+386" }, // Slovenia
    { mask: "+{0[00]} 000 000 0000", startsWith: "+" }, // Fallback for other international numbers
  ];
}

function getPhoneAndEmailMasks() {
  return [...getPhoneMasks(), getEmailMask()];
}

function getEmailMask() {
  return {
    mask: "v[x]y.z",
    blocks: {
      v: { mask: /[a-zA-Z0-9_+&*-]*/ },
      x: { mask: /(?:\.[a-zA-Z0-9_+&*-]*)*/ },
      y: { mask: /@(?:[a-zA-Z0-9-]*)*/ },
      z: { mask: /[a-zA-Z]*/ },
    },
    startsWith: "a",
    placeholderChar: "_",
    lazy: true,
  };
}

function dispatchMask(appended, dynamicMasked) {
  const input = dynamicMasked.value + appended;

  if (input.startsWith("0")) {
    return dynamicMasked.compiledMasks.find((m) => m.startsWith === "0");
  } else if (input.startsWith("+")) {
    const specificMask = dynamicMasked.compiledMasks.find((m) =>
      input.startsWith(m.startsWith)
    );
    return (
      specificMask ||
      dynamicMasked.compiledMasks.find((m) => m.startsWith === "+")
    );
  } else if (/[a-zA-Z]/.test(input[0])) {
    return dynamicMasked.compiledMasks[dynamicMasked.compiledMasks.length - 1];
  } else {
    return dynamicMasked.compiledMasks.find((m) => m.startsWith === "");
  }
}

function handleFormSubmit(event) {
  const inputElement = document.querySelector(".js-masked")
  if(!inputElement) {
    throw new Error("unexpected error, .js-masked should be found")
  }
  const value = `${inputElement.value}`.replaceAll(" ", "")
  inputElement.classList.remove("input-error");

  const emailRegex =
    /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  const phoneRegex = /^(\+|0)\d{6,}$/;

  const isValidPhone = phoneRegex.test(value)
  const isValidEmail = emailRegex.test(value)
  const isValid = isValidPhone || isValidEmail
  if (!isValid) {
    const isPhoneLike = value.startsWith("0") || value.startsWith("+")
    event.detail.formSubmission.stop()
    if(isPhoneLike && inputElement.classList.contains('js-phone-mask'))
      inputElement.setCustomValidity(
        "Un numéro de téléphone valide est requis" 
      );
    else if(!isPhoneLike && inputElement.classList.contains('js-email-mask'))
      inputElement.setCustomValidity(
        "Un email valide est requis " 
      );
    else
      inputElement.setCustomValidity(
        "Vérifier ce champs " 
      );

    inputElement.reportValidity();
    inputElement.classList.add("input-error");
  } else {
    inputElement.setCustomValidity("");
  }
}
